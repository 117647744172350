@media (min-width: 600px){
  .gmail-ui {
    border-radius: 10px;
    background: url('/images/gmail-ui.png') no-repeat;
    background-size: 100%;
    background-color: #f9fafb;
    width: 100%;
    padding-top: 18.5%;
    padding-bottom: 20px;
    padding-left: 15.7%;
    padding-right: 1%;
  }
}
@media (max-width: 599px){
  .gmail-ui {
    padding: 10px;
    background: white;
  }
}
@media (max-width: 599px){
  .gmail-ui {
    background: url('/images/gmail-ui-android.png') no-repeat;
    background-size: 100%;
    background-color: #ffffff;
    padding-top: 24%;
  }
}

@media (min-width: 600px){
  .gmail-ui--body {
    background: white;
    padding-top: 50px;
    min-height: 1200px;
  }
}
@media (max-width: 599px){
  .gmail-ui--body {
    background: white;
    padding-top: 10px;
  }
}
