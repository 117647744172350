body.report-mailer {
  -webkit-font-smoothing: antialiased;
  width: 100% !important;
  -webkit-text-size-adjust: none;
  font-family: sans-serif, 'Helvetica Neue';
  font-weight: 100;
  margin-top: 0;
}

.report-mailer .preheader {
  color: #fff;
  font-size: 0;
  visibility: hidden;
  display: none !important;
  opacity: 0;
  line-height: 1px;
  mso-hide: all;
  overflow: hidden;
  max-height: 0px;
  max-width: 0px;
}
.report-mailer .align-right {
  text-align: right;
}
.report-mailer .align-center {
  text-align: center;
}
.report-mailer .bolder {
  font-weight: bold;
}
.report-mailer .bigger {
  font-size: 22px !important;
}
.report-mailer .smaller {
  font-size: 12px !important;
}
.report-mailer .container {
  max-width: 700px;
  margin: 0 auto;
}
.report-mailer h2 {
  font-weight: 200;
  font-size: 18px;
  margin: 10px 10px 10px 0;
}
.report-mailer img {
  width: 100%;
  display: inline;
  vertical-align: middle;
}
.report-mailer img.medal {
  width: 11px;
  height: 14px;
}
.report-mailer p {
  line-height: 25px;
}
.report-mailer p a {
  color: #673ab7;
  text-decoration: underline;
}
.report-mailer li {
  line-height: 1.5em;
}
.report-mailer .widget-table {
  margin: 20px 0;
}
.report-mailer .summary {
  border-collapse: separate;
  border-spacing: 1px;
}
.report-mailer .summary img.summary-logo {
  max-width: 130px;
  margin-bottom: 20px;
}
.report-mailer .summary .label {
  margin-bottom: 10px;
}
.report-mailer .summary .percentage {
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 5px;
}
.report-mailer .summary .bg-true {
  background: #43a047;
}
.report-mailer .summary .bg-false {
  background: #e53935;
}
.report-mailer .summary .compared-to {
  background: #f1f1f1;
  color: #999;
  line-height: 2em;
  font-size: 12px;
  text-align: center;
}
.report-mailer .top-pages-item td {
  padding: 5px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eee;
  font-size: 13px;
}
.report-mailer .top-pages-item a, .report-mailer .top-pages-item a:visited {
  text-decoration: none;
  color: #333;
}
.report-mailer .top-pages-item .author {
  font-size: 11px;
  color: #999;
  font-style: italic;
}
.report-mailer .top-pages-item .number {
  color: #333;
  text-align: right;
  vertical-align: top;
  font-weight: bold;
}
.report-mailer .top-page-item {
  padding: 15px;
  background-color: #546e7a;
  font-size: 14px;
  color: #fff;
}
.report-mailer .top-page-item a, .report-mailer .top-page-item a:visited {
  text-decoration: none;
  color: #fff;
}
.report-mailer .top-page-item .sources {
  font-size: 12px;
  padding-top: 15px;
  color: #ccc;
}
.report-mailer .top-page-item .pageviews {
  display: block;
  font-size: 12px;
}
.report-mailer .report-footer td {
  background-color: #2d3748;
}
.report-mailer .report-footer .left {
  text-align: left;
  color: #fff;
  font-size: 13px;
  padding: 5%;
}
.report-mailer .report-footer .right {
  text-align: right;
  padding-right: 5%;
  vertical-align: middle;
}
.report-mailer .report-footer a {
  text-decoration: underline;
  color: #fff;
}
.mail-button {
  padding: 10px 0;
  border: 0;
  text-decoration: none !important;
  max-width: 200px;
  display: block;
  text-align: center;
  border: 1px solid #26e4b6;
  border-radius: 2px;
  background: #29f3c0;
  color: #0d1117 !important;
  margin-top: 30px;
  margin-bottom: 50px;
}
