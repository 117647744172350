@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "gmail.scss";
@import "slack.css";
@import "typewriter.css";
@import "report-mailer.css";

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.token {
  @apply font-bold bg-slate-100 rounded-sm border border-slate-200 px-2 py-1 font-mono;
}

.mark {
  display: inline-block;
  position: relative;
  z-index: 2;
}
.mark::before {
  content: "";
  position: absolute;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  height: 100%;
  width: calc(100% + 8px);
  left: -4px;
  z-index: -1;
  transform: rotate(-2deg);
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
}
