.slack-ui {
  border-radius: 10px;
  background: url('/images/slack-ui.png?v=2') no-repeat;
  background-size: 100%;
  background-color: #3F0F40;
  width: 100%;
  padding-top: 7%;
  padding-bottom: 0;
  padding-left: 24%;
  padding-right: 0;
}

@media (max-width: 599px){
  .slack-ui {
    padding-left: 4px;
    padding-bottom: 4px;
  }
}
.slack-ui--body {
  background: white;
  padding: 0;
  max-height: 800px;
}