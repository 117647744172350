@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.typewriter-cursor::after {
  content: "|";
  font-size: 1.5em;
  font-weight: bold;
  animation: blink-animation 0.4s steps(2, start) infinite;
  -webkit-animation: blink-animation 0.4s steps(2, start) infinite;
}
